<button
    (click)="toggle()"
    type="button"
    class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
    role="switch"
>
    <span  class="pointer-events-none absolute h-full w-full rounded-md bg-white"></span>
    <span
        class="pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
        [ngClass]="[enabled() ? 'bg-red-600' : 'bg-gray-200']"
    ></span>
    <span
        class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
        [ngClass]="[enabled() ? 'translate-x-5' : 'translate-x-0']"
    ></span>
</button>