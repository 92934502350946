import { EventEmitter, Injectable } from '@angular/core';
import { MapMarkerConfig } from '../../types/search/map-marker-config';
import { MapMarker } from '../../types/search/map-marker';
import { makeMapMarker } from '../../core/factories/search/make-map-marker';
import { LatLngBounds } from 'leaflet';
import { concatMap, delay, finalize, from, Observable, Subject, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MapService {

    private readonly markers = new Map<string, MapMarker>();
    private batchSize = 25;
    private batchDelay = 5; // milliseconds
    private cancelBatchAdd$ = new Subject<void>();

    // ----------[ EventEmitters and Subjects ]----------

    public readonly markerAdded = new EventEmitter<MapMarker>();
    public readonly markersAddedBatch = new Subject<MapMarker[]>();
    public readonly markerRemoved = new EventEmitter<string>();
    public readonly clearedMarkers = new EventEmitter<void>();
    public readonly scrollTo = new EventEmitter<{ lat: number, lng: number, xOffset?: number }>();
    public readonly zoomTo = new EventEmitter<{ lat: number, lng: number, zoom: number }>();
    public readonly highlight = new EventEmitter<{ lat: number, lng: number, radius: number }>();
    public readonly centerChanged = new EventEmitter<{ lat: number, lng: number }>();
    public readonly boundsChanged = new EventEmitter<LatLngBounds>();
    public readonly batchAddingComplete = new Subject<void>();

    // ----------[ Constructor ]----------

    constructor() {
        // Empty constructor for potential future dependency injections
    }

    // ----------[ Methods ]----------

    public clear() {
        this.markers.forEach(marker => marker._delete());
        this.markers.clear();
        this.clearedMarkers.emit();
    }

    public addMarkers(configs: MapMarkerConfig[]): Observable<MapMarker[]> {
        // Cancel any ongoing batch operation
        this.cancelBatchAdd$.next();

        // Create a new cancellation token for this operation
        const currentCancelToken$ = new Subject<void>();
        this.cancelBatchAdd$ = currentCancelToken$;

        const batches = this.createBatches(configs);
        let allAddedMarkers: MapMarker[] = [];

        return from(batches).pipe(
            concatMap(batch => {
                return new Observable<MapMarker[]>(observer => {
                    const batchMarkers: MapMarker[] = [];
                    batch.forEach(config => {
                        const marker = makeMapMarker(config);
                        this.markers.set(marker.id, marker);
                        batchMarkers.push(marker);
                        this.markerAdded.emit(marker);
                    });
                    allAddedMarkers = allAddedMarkers.concat(batchMarkers);
                    observer.next(batchMarkers);
                    observer.complete();
                }).pipe(
                    tap(batchMarkers => this.markersAddedBatch.next(batchMarkers)),
                    delay(this.batchDelay) // Delay after processing each batch
                );
            }),
            takeUntil(currentCancelToken$),
            finalize(() => {
                this.batchAddingComplete.next();
                currentCancelToken$.complete();
            }),
            map(() => allAddedMarkers) // Emit all added markers at the end
        );
    }

    private createBatches(configs: MapMarkerConfig[]): MapMarkerConfig[][] {
        const batches: MapMarkerConfig[][] = [];
        for (let i = 0; i < configs.length; i += this.batchSize) {
            batches.push(configs.slice(i, i + this.batchSize));
        }
        return batches;
    }

    public removeMarker(id: string): void {
        const marker = this.markers.get(id);
        if (!marker) {
            return;
        }
        marker._delete();
        this.markers.delete(id);
        this.markerRemoved.emit(id);
    }

    public getMarker(id: string): MapMarker | null {
        return this.markers.get(id) || null;
    }

    public scrollToMarker(id: string): void {
        const marker = this.getMarker(id);
        if (marker) {
            this.scrollToCoordinates(marker.coordinates);
        }
    }

    public scrollToCoordinates(coordinates: { lat: number, lng: number }): void {
        this.scrollTo.emit(coordinates);
    }

    public highlightCoordinates(coordinates: { lat: number, lng: number }, radius: number): void {
        this.highlight.emit({ lat: coordinates.lat, lng: coordinates.lng, radius });
    }

    public disableHighlight(): void {
        this.highlight.emit({ lat: 0, lng: 0, radius: 0 });
    }

    public setBatchConfig(size: number, delay: number): void {
        this.batchSize = size;
        this.batchDelay = delay;
    }
}
