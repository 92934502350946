<div class="flex items-center divide-x space-x-6 py-3">
    <div class="pl-4 flex space-x-6">
        @if(!isSearching()) {
            <a class="flex items-center hover:underline cursor-pointer" (click)="onSearchClick()">
                <ng-icon
                    class="text-black mr-2"
                    name="heroMagnifyingGlass"
                    size="1.2em"
                />
                <div class="text-sm">
                        Begin met zoeken
                </div>
            </a>
        } @else {
            <a class="flex items-center hover:underline cursor-pointer text-gray-500" (click)="onSearchClick()">
                <ng-icon
                    class="text-black mr-2"
                    name="heroMagnifyingGlass"
                    size="1.2em"
                />
                <div class="text-sm">
                    Andere categorie
                </div>
            </a>
        }
    </div>
    <div class="px-4 text-sm text-gray-500">
        {{ total() >= 10000 ? '10.000+' : total() }}
        <ng-container i18n="@@component.search-details.result-count-suffix">
            resultaten
        </ng-container>
    </div>
</div>
