import { Component, input } from '@angular/core';
import { Location } from "../../../../../graphql/generated";

@Component({
    selector: 'app-company-address',
    standalone: true,
    imports: [],
    template: `{{
        location().properties?.formattedAddress ||
        location().properties?.address?.city
    }}
    `
})
export class CompanyAddressComponent {

    location = input.required<Location>();

}
