<div class="flex justify-between space-x-3 order-b py-2 pr-3 w-full max-w-[35vw]">
    <div #scrollContainer class="relative flex-grow w-full max-w-full overflow-x-scroll hide-scrollbar">
        <div class="relative overflow-y-hidden">
            <div class="absolute z-20 right-0 h-10 w-8 bg-gradient-to-l from-white from-10% to-transparent pointer-events-none"></div>
            <div autoAnimate class="flex w-full max-w-full overflow-y-hidden overflow-x-scroll hide-scrollbar pl-4 pr-4">
                @for (filter of filterItems(); track filter.id) {
                    <div class="whitespace-nowrap border inline-flex items-center rounded-md bg-white text-xs px-2 py-1 mr-1">
                        <span class="pr-1">
                            {{ filter.label }}
                        </span>
                        <ng-icon
                            (click)="removeFilterValue(filter)"
                            class="cursor-pointer"
                            name="heroXMark"
                            size="1.2em"
                        />
                    </div>
                } @empty {
                    <div class="text-gray-300 text-xs mt-1.5 mb-1.5">
                        Geen filters {{ properties().length > 0 ? 'geselecteerd' : 'beschikbaar' }}
                    </div>
                }
            </div>
        </div>
    </div>
    @if(properties().length > 0) {
        <div class="flex">
            <button
                (click)="toggleFilterMenu()"
                type="button"
                class="flex whitespace-nowrap text-xs pl-4 pr-4 py-1 border border-slate-300 rounded-md focus:outline-none focus:ring-border-brand items-center bg-slate-100 hover:bg-slate-200 active:bg-slate-300"
            >
                <ng-icon name="heroAdjustmentsVertical" size="1.2em" class="mr-2"/>
                Filter resultaten
            </button>
        </div>
    }
</div>
