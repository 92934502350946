import { Component, EventEmitter, forwardRef, input, OnInit, Output, signal } from '@angular/core';
import { NgClass } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-toggle',
    standalone: true,
    imports: [
        NgClass,
        FormsModule
    ],
    templateUrl: './toggle.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true
        }
    ]
})
export class ToggleComponent implements OnInit, ControlValueAccessor {

    @Output()
    change = new EventEmitter<boolean>();

    default = input<boolean>(false);

    enabled = signal<boolean>(false);

    toggle() {
        this.enabled.update(enabled => !enabled);
        this.onChange(this.enabled());
        this.onTouched();
        this.change.emit(this.enabled());
    }

    ngOnInit() {
        this.enabled.set(this.default());
    }

    // ControlValueAccessor methods
    writeValue(value: boolean): void {
        this.enabled.set(value);
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        // Implement if you want to support disabling the toggle
    }

    private onChange: (value: boolean) => void = () => {
    };

    private onTouched: () => void = () => {
    };
}
