import { Component, computed, input } from '@angular/core';
import { NgIcon } from "@ng-icons/core";
import {SearchService} from "../../../services/search.service";

@Component({
    selector: 'app-search-details',
    standalone: true,
    imports: [
        NgIcon
    ],
    templateUrl: './search-details.component.html',
})
export class SearchDetailsComponent {

    total = input.required<number>();
    //
    // search = this.searchSupplyContext.select('search');
    //
    // date = computed(() => this.search()?.parameters?.date)

    constructor(
        private readonly searchService: SearchService
    ) {}

    isSearching = computed(() => this.searchService.select('categoryId')() !== null);

    onSearchClick() {
        // create a reference to the input element
        const input = document.getElementById('navbarCategorySearchInput');
        // focus on the input element
        input!.focus();
    }
}
