import { MapMarkerConfig } from "../../../types/search/map-marker-config";
import { icon, IconOptions, marker as makeMarker, PointTuple } from "leaflet";
import { MapMarker } from "../../../types/search/map-marker";
import { MapMarkerState } from "../../../types/search/map-marker-state";
import { EventEmitter } from "@angular/core";

type MarkerType = 'primary' | 'secondary';

export function makeMapMarker(config: MapMarkerConfig): MapMarker {
    let state: MapMarkerState = 'idle';

    const leafletMarker = makeMarker(config.coordinates);

    const marker: MapMarker = {
        _marker: leafletMarker,
        id: config.id,
        coordinates: config.coordinates,
        popup: config.popup,
        setState: (newState: MapMarkerState) => {
            state = newState;
            updateMarker();
        },
        clicked: new EventEmitter(),
        _delete: () => {
            leafletMarker.remove();
            marker.clicked.complete();
        }
    }

    function updateMarker() {
        const currentState = config.state?.() || state;
        leafletMarker.setIcon(icon(getIconOptions(config.type, currentState)));
        leafletMarker.setZIndexOffset(Z_INDICES[currentState]);
        config.onStateChange?.(currentState);
    }

    leafletMarker.on('mouseover', () => {
        state = 'hover';
        updateMarker();
    });

    leafletMarker.on('mouseout', () => {
        state = 'idle';
        updateMarker();
    });

    leafletMarker.on('click', () => {
        marker.clicked.emit();
        updateMarker();
    });

    if (config.onClick) {
        marker.clicked.subscribe(config.onClick);
    }

    updateMarker();

    return marker;
}

function getIconOptions(type: MarkerType, state: MapMarkerState): IconOptions {
    switch (state) {
        case "active":
            return {
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                iconUrl: ICON_URLS.active,
            };
        default:
            return {
                iconSize: [28, 28],
                iconAnchor: [14, 14],
                iconUrl: ICON_URLS[state][type],
            };
    }
}

const ICON_URLS = {
    active: 'assets/images/markers/marker-active.svg',
    hover: {
        primary: 'assets/images/markers/marker-hover.svg',
        secondary: 'assets/images/markers/marker-hover-secondary.svg',
    },
    idle: {
        primary: 'assets/images/markers/marker-primary.svg',
        secondary: 'assets/images/markers/marker-secondary.svg',
    },
};

const Z_INDICES: Record<MapMarkerState, number> = {
    active: 1000,
    hover: 500,
    idle: 0,
};